<template>
    <div class="main">

        <agent-info-comp></agent-info-comp>

        <agent-sub-title>통계표</agent-sub-title>

        <div class="search">
            <partner-sub-selector-comp @setPartnerId="setPartnerId"></partner-sub-selector-comp>
            <date-selector-comp @setStartDate="setStartDate" @setEndDate="setEndDate"></date-selector-comp>
            <el-button type="primary" size="mini" style="margin-left: 5px" @click="search">검색</el-button>

            <div style="width: 100%;display: flex;justify-content: flex-start;align-items: center;;margin-left: 20px">
                <el-button type="info" size="small" @click="showtab(1)">기본정산표</el-button>
                <el-button type="info" size="small" @click="showtab(2)">스포츠&인플레이</el-button>
                <el-button type="info" size="small" @click="showtab(3)">미니게임</el-button>
                <el-button type="info" size="small" @click="showtab(4)">카지노&슬롯</el-button>
                <el-button type="info" size="small" @click="showtab(5)">홀덤</el-button>
            </div>
        </div>
        <div class="data">
            <table class="agent_table" v-if="currenttapnumber === 1">
                <tr>
                    <th style="width: 11%">날짜</th>
                    <th style="width: 10%">신규회원/(블럭)</th>
                    <th style="width: 11%">입금</th>
                    <th style="width: 11%">출금</th>
                    <th style="width: 11%">총판출금</th>
                    <th style="width: 11%">수익금</th>
                    <th style="width: 10%">첫충포인트</th>
                    <th style="width: 10%">매충포인트</th>
                    <th style="width: 10%">첫매충총지급</th>
                </tr>
                <tr v-for="item in sdata">
                    <td>   {{item.date|datef('yyyy-MM-DD')}}</td>
                    <td>{{item.siteMemberCount +'명('+item.siteBlockMemberCount+')'}}</td>
                    <td style="color: red">{{item.rechargeTotal|comma}}</td>
                    <td style="color: blue">{{item.exchangeTotal|comma}}</td>
                    <td style="color: blueviolet">{{item.exchangePartnerTotal|comma}}</td>
                    <td>
                      <span v-if="(item.rechargeTotal - item.exchangeTotal - item.exchangePartnerTotal) >= 0">  {{(item.rechargeTotal - item.exchangeTotal - item.exchangePartnerTotal)|comma}}</span>
                      <span v-if="(item.rechargeTotal - item.exchangeTotal - item.exchangePartnerTotal) < 0">  {{(item.rechargeTotal - item.exchangeTotal - item.exchangePartnerTotal)|comma}}</span>
                    </td>
                    <td>{{(item.accumulateFirstRechargePoint)|comma}}</td>
                    <td>{{(item.accumulateDayRechargePoint )|comma}}</td>
                    <td>{{(item.accumulateFirstRechargePoint + item.accumulateDayRechargePoint )|comma}}</td>
                </tr>
                <tr>
                    <th>전체통계</th>
                    <th>{{totalUser +'명'}}</th>
                    <th>{{totalRecharge|comma}}</th>
                    <th>{{totalExchange|comma}}</th>
                    <th>{{totalExchangePartner|comma}}</th>
                    <th class="text-green text-blod">
                      <span v-if="totalREProfit >=0 " style="color: red">  {{(totalREProfit)|comma}}</span>
                      <span v-if="totalREProfit < 0 ">  {{(totalREProfit)|comma}}</span>
                    </th>
                    <th>{{(totalAccumulateFirstRechargeBonusPoint)|comma}}</th>
                    <th>{{(totalAccumulateDayRechargeBonusPoint )|comma}}</th>
                    <th>{{(totalAccumulateFirstRechargeBonusPoint + totalAccumulateDayRechargeBonusPoint )|comma}}</th>
                </tr>
            </table >

            <table class="agent_table" v-if="currenttapnumber === 2">
                <tr>
                    <th style="width: 11%">날짜</th>
                    <th style="width: 8%">스포츠배팅건수</th>
                    <th style="width: 8%">스포츠베팅금액</th>
                    <th style="width: 8%">스포츠당첨금액</th>
                    <th style="width: 8%">스포츠취소금액</th>
                    <th style="width: 4%">베팅회원수</th>
                    <th style="width: 8%">수익</th>
                    <th style="width: 8%">Inplay배팅건수</th>
                    <th style="width: 8%">Inplay베팅금액</th>
                    <th style="width: 8%">Inplay당첨금액</th>
                    <th style="width: 8%">Inplay취소금액</th>
                    <th style="width: 4%">베팅회원수</th>
                    <th style="width: 8%">수익</th>
                </tr>
                <tr v-for="item in sdata">
                    <td>   {{item.date|datef('yyyy-MM-DD')}}</td>
                    <td>{{(item.sportsBetCount) + '건'}}</td>
                    <td>{{(item.sportsBetCashTotal)|comma}}</td>
                    <td>{{(item.sportsBetWinCashTotal)|comma}}</td>
                    <td>{{(item.sportsBetCancelCashTotal)|comma}}</td>
                    <td>({{item.betSportsUserCount}}명)</td>
                    <td class="text-green">
                        {{(item.sportsBetCashTotal-(item.sportsBetSpecialCashTotal+item.sportsBetCancelCashTotal+item.sportsBetWinCashTotal))|comma}}

                    </td>
                    <td>{{(item.inplayBetCount) + '건'}}</td>
                    <td>{{(item.inplayBetCashTotal)|comma}}</td>
                    <td>{{(item.inplayBetWinCashTotal)|comma}}</td>
                    <td>{{(item.inplayBetCancelCashTotal)|comma}}</td>
                    <td>  ({{item.betInplayUserCount}}명)</td>
                    <td class="text-green">
                        {{(item.inplayBetCashTotal-(item.inplayBetSpecialCashTotal+item.inplayBetCancelCashTotal+item.inplayBetWinCashTotal))|comma}}

                    </td>
                </tr>
                <tr>
                    <th>전체통계</th>
                    <th>{{(totalBetCount) + '건'}}</th>
                    <th>{{(totalBetAmount)|comma}}</th>
                    <th>{{(totalWinAmount)|comma}}</th>
                    <th>{{(totalBetCancelAmount)|comma}}</th>
                    <th>-</th>
                    <th class="text-green text-blod">
                        {{(totalBetAmount-totalWinAmount-totalBetCancelAmount)|comma}}
                    </th>
                    <th>{{(totalInplayBetCount) + '건'}}</th>
                    <th>{{(totalInplayBetAmount)|comma}}</th>
                    <th>{{(totalInplayWinAmount)|comma}}</th>
                    <th>{{(totalInplayBetCancelAmount)|comma}}</th>
                    <th>-</th>
                    <th class="text-green text-blod">
                        {{(totalInplayBetAmount-totalInplayWinAmount-totalInplayBetCancelAmount)|comma}}
                    </th>

                </tr>
            </table>

            <table class="agent_table" v-if="currenttapnumber === 3">
                <tr>
                    <th style="width: 11%">날짜</th>
                    <th style="width: 8%">미니게임배팅건수</th>
                    <th style="width: 8%">미니게임베팅금액</th>
                    <th style="width: 8%">미니게임당첨금액</th>
                    <th style="width: 8%">미니게임취소금액</th>
                    <th style="width: 4%">베팅회원수</th>
                    <th style="width: 8%">수익</th>
                </tr>
                <tr v-for="item in sdata">
                    <td>   {{item.date|datef('yyyy-MM-DD')}}</td>
                    <td>{{(item.leisureBetCount ) + '건'}}</td>
                    <td>{{(item.leisureBetCashTotal )|comma}}</td>
                    <td>{{(item.leisureBetWinCashTotal )|comma}}</td>
                    <td>{{(item.leisureBetSpecialCashTotal+item.leisureBetCancelCashTotal)|comma}}</td>
                    <td> {{item.betLeisureUserCount}}명</td>
                    <td class="text-green">
                        {{(item.leisureBetCashTotal-(item.leisureBetWinCashTotal+item.leisureBetSpecialCashTotal+item.leisureBetCancelCashTotal)
                        )|comma}}

                    </td>
                </tr>
                <tr>
                    <th>전체통계</th>
                    <th>{{(totalLBetCount) + '건'}}</th>
                    <th>{{(totalLBetAmount)|comma}}</th>
                    <th>{{(totalLWinAmount)|comma}}</th>
                    <th>{{(totalLCancelAmount)|comma}}</th>
                    <th>-</th>
                    <th class="text-green text-blod">
                        {{(totalLBetAmount-totalLBetAmount-totalLCancelAmount)|comma}}
                    </th>
                </tr>
            </table>

            <table class="agent_table" v-if="currenttapnumber === 4">
                <tr>
                    <th style="width: 11%">날짜</th>
                    <th style="width: 8%">카지노배팅건수</th>
                    <th style="width: 8%">카지노베팅금액</th>
                    <th style="width: 8%">카지노당첨금액</th>
                    <th style="width: 8%">수익</th>
                    <th style="width: 8%">슬롯배팅건수</th>
                    <th style="width: 8%">슬롯베팅금액</th>
                    <th style="width: 8%">슬롯당첨금액</th>
                    <th style="width: 8%">수익</th>
                </tr>
                <tr v-for="item in sdata">
                    <td>   {{item.date|datef('yyyy-MM-DD')}}</td>
                    <td>{{(item.honorCasinoBetCountTotal )|comma}}</td>
                    <td>{{(item.honorCasinoBetCashTotal )|comma}}</td>
                    <td>{{(item.honorCasinoWinCashTotal )|comma}}</td>
                    <td class="text-green">{{(item.honorCasinoBetCashTotal-item.honorCasinoWinCashTotal)|comma}}</td>
                    <td>{{(item.honorSlotBetCountTotal )|comma}}</td>
                    <td>{{(item.honorSlotBetCashTotal )|comma}}</td>
                    <td>{{(item.honorSlotWinCashTotal )|comma}}</td>
                    <td class="text-green">{{(item.honorSlotBetCashTotal-item.honorSlotWinCashTotal)|comma}}</td>
                </tr>
                <tr>
                    <th> 전체통계</th>
                    <th>{{(honorCasinoBetCountTotal )|comma}}</th>
                    <th>{{(honorCasinoBetCashTotal )|comma}}</th>
                    <th>{{(honorCasinoWinCashTotal )|comma}}</th>
                    <th class="text-green">{{(honorCasinoBetCashTotal-honorCasinoWinCashTotal)|comma}}</th>
                    <th>{{(honorSlotBetCountTotal )|comma}}</th>
                    <th>{{(honorSlotBetCashTotal )|comma}}</th>
                    <th>{{(honorSlotWinCashTotal )|comma}}</th>
                    <th class="text-green">{{(honorSlotBetCashTotal-honorSlotWinCashTotal)|comma}}</th>
                </tr>
            </table>

          <table class="agent_table" v-if="currenttapnumber === 5">
            <tr>
              <th style="width: 20%">날짜</th>
              <th style="width: 20%">배팅건수</th>
              <th style="width: 20%">베팅금액</th>
              <th style="width: 20%">당첨금액</th>
              <th style="width: 20%">수익</th>
            </tr>
            <tr v-for="item in sdata">
              <td>   {{item.date|datef('yyyy-MM-DD')}}</td>
              <td>{{(item.reachCasinoBetCountTotal )|comma}}</td>
              <td>{{(item.reachCasinoBetCashTotal )|comma}}</td>
              <td>{{(item.reachCasinoWinCashTotal )|comma}}</td>
              <td class="text-green">{{(item.reachCasinoBetCashTotal-item.reachCasinoWinCashTotal)|comma}}</td>
            </tr>
            <tr>
              <th> 전체통계</th>
              <th>{{(reachCasinoBetCountTotal )|comma}}</th>
              <th>{{(reachCasinoBetCashTotal )|comma}}</th>
              <th>{{(reachCasinowinCashTotal )|comma}}</th>
              <th class="text-green">{{(reachCasinoBetCashTotal - reachCasinowinCashTotal)|comma}}</th>
            </tr>
          </table>

        </div>
    </div>
</template>

<script>

    import {Loading} from 'element-ui';
    import DateSelectorComp from "../../components/administrator/DateSelectorComp";
    import AgentSubTitle from "../../components/agent/AgentSubTitle";
    import PartnerSubSelectorComp from "../../components/agent/PartnerSubSelectorComp";
    import {agentMixin} from "../../common/agent/agentMixin";
    import {agentMainStatistic} from "../../network/agent/commonRequest";
    import agentConst from "../../common/agent/agentConst";
    import AgentInfoComp from "../../components/agent/AgentInfoComp";

    export default {
        name: "AgentMainStatisticSimple",
        components: {
            AgentInfoComp,
            PartnerSubSelectorComp,
            AgentSubTitle,
            DateSelectorComp,
        },
        mixins: [agentMixin],
        data() {
            return {
                currenttapnumber:1,
                agent: {id: null},
                sdata: [],
                months: [
                    {'key': '1월', 'val': '1'}, {'key': '2월', 'val': '2'}, {'key': '3월', 'val': '3'}, {
                        'key': '4월',
                        'val': '4'
                    }, {'key': '5월', 'val': '5'}, {'key': '6월', 'val': '6'},
                    {'key': '7월', 'val': '7'}, {'key': '8월', 'val': '8'}, {'key': '9월', 'val': '9'}, {
                        'key': '10월',
                        'val': '10'
                    }, {'key': '11월', 'val': '11'}, {'key': '12월', 'val': '12'}
                ],
                totalUser: 0,
                totalRecharge: 0,
                totalExchange: 0,
                totalExchangePartner: 0,
                totalCasinoRecharge: 0,
                totalCasinoExchange: 0,
                totalAccumulatePoints: 0,
                totalBetCount: 0,
                totalBetCancelAmount: 0,
                totalBetSpecialAmount: 0,
                totalBetAmount: 0,
                totalWinAmount: 0,
                totalBetUserCount:0,
                totalInplayWinAmount: 0,
                totalInplayBetCancelAmount: 0,
                totalInplayBetAmount: 0,
                totalInplayBetCount: 0,
                totalInplayBetUserCount: 0,
                totalLBetCount: 0,
                totalLBetAmount: 0,
                totalLWinAmount: 0,
                totalLBetUserCount: 0,
                totalREProfit: 0,
                totalCasinoREProfit: 0,
                totalBetProfit: 0,
                totalPatnerStAmount: 0,
                totalWinPatnerStAmount: 0,
                totalPartnerBetStatisticAmount: 0,
                totalPartnerBetWinStatisticAmount: 0,
                totalPartnerLBetStatisticAmount: 0,
                totalPartnerLBetWinStatisticAmount: 0,
                totalLoginUserCount: 0,
                totalAccumulateManagerAddCash: 0,
                totalAccumulateManagerAddPoint: 0,
                totalAccumulateFirstRechargeBonusPoint: 0,
                totalAccumulateDayRechargeBonusPoint: 0,
                totalAccumulateBetFailPoint: 0,
                totalAccumulateBetFail2RefPoint: 0,
                totalLCancelAmount: 0,
                totalLSpecialAmount: 0,

                casinoBetCountTotal: 0,
                casinoBetCashTotal: 0,
                casinowinCashTotal: 0,
                casinocancelCashTotal: 0,
                slotBetCountTotal: 0,
                slotBetCashTotal: 0,
                slotwinCashTotal: 0,
                slotcancelCashTotal: 0,
                fishRechargCashTotal: 0,
                fishExchangeCashTotal: 0,

                reachCasinoBetCountTotal: 0,
                reachCasinoBetCashTotal: 0,
                reachCasinowinCashTotal: 0,
                reachSlotBetCountTotal: 0,
                reachSlotBetCashTotal: 0,
                reachSlotwinCashTotal: 0,

                honorCasinoBetCountTotal: 0,

                honorCasinoBetCashTotal: 0,
                honorCasinoWinCashTotal: 0,

                honorSlotBetCountTotal: 0,

                honorSlotBetCashTotal: 0,
                honorSlotWinCashTotal: 0,
            }
        }
        ,
        methods: {
            showtab(num){
                this.currenttapnumber = num
                this.getMainStatistic();
            },
            getMainStatistic() {
                let loadingInstance = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                agentMainStatistic(this.agent, this.startTime, this.endTime,this.currenttapnumber).then(res => {
                    this.sdata = res.data.data
                    this.setTotal(this.sdata)
                    loadingInstance.close();
                })
            },
            search() {
                this.getMainStatistic()
            },
            setPartnerId(partnerId) {
                this.agent.id = partnerId;
                this.getMainStatistic()
            },
            setStartDate(date) {
                this.startTime = date
            },
            setEndDate(date) {
                this.endTime = date
            },
            setTotal(list) {
                let totalRecharge = 0;
                let totalExchange = 0;
                let totalExchangePartner = 0;
                let totalCasinoRecharge = 0;
                let totalCasinoExchange = 0;
                let totalUser = 0;
                let totalBetCount = 0;

                let totalBetAmount = 0;

                let totalWinAmount = 0;
                let totalBetCancelAmount = 0;
                let totalBetSpecialAmount = 0;

                let totalLBetCount = 0;
                let totalLBetAmount = 0;
                let totalLWinAmount = 0;
                let totalLLoseAmount = 0;
                let totalLCancelAmount = 0;
                let totalLSpecialAmount = 0;
                let totalInplayWinAmount = 0;
                let totalInplayBetCount = 0;
                let totalInplayBetAmount = 0;
                let totalInplayBetCancelAmount = 0;
                let totalAccumulatePoints = 0;
                let totalAccumulateManagerAddCash = 0;
                let totalAccumulateManagerAddPoint = 0;
                let totalAccumulateFirstRechargeBonusPoint = 0;
                let totalAccumulateDayRechargeBonusPoint = 0;
                let totalAccumulateBetFailPoint = 0;
                let totalAccumulateBetFail2RefPoint = 0;
                let totalPatnerStAmount = 0;
                let totalWinPatnerStAmount = 0;
                let totalPartnerBetStatisticAmount = 0;
                let totalPartnerBetWinStatisticAmount = 0;
                let totalPartnerLBetStatisticAmount = 0;
                let totalPartnerLBetWinStatisticAmount = 0;
                let totalLoginUserCount = 0;

                let casinoBetCountTotal = 0;
                let casinoBetCashTotal = 0;
                let casinowinCashTotal = 0;
                let casinocancelCashTotal = 0;
                let slotBetCountTotal = 0;
                let slotBetCashTotal = 0;
                let slotwinCashTotal = 0;
                let slotcancelCashTotal = 0;
                let fishRechargCashTotal = 0;
                let fishExchangeCashTotal = 0;

                let reachCasinoBetCountTotal = 0;
                let reachCasinoBetCashTotal = 0;
                let reachCasinowinCashTotal = 0;
                let reachSlotBetCountTotal = 0;
                let reachSlotBetCashTotal = 0;
                let reachSlotwinCashTotal = 0;

                let honorCasinoBetCountTotal = 0;
                let honorCasinoBetCashTotal = 0;
                let honorCasinowinCashTotal = 0;
                let honorSlotBetCountTotal = 0;
                let honorSlotBetCashTotal = 0;
                let honorSlotwinCashTotal = 0;

                //베팅손익금
                let totalBetWinAmount = 0;

                list.map(s => {
                    totalRecharge += s.rechargeTotal;
                    totalExchange += s.exchangeTotal;
                    totalExchangePartner += s.exchangePartnerTotal;
                    totalCasinoRecharge += s.casinoRechargeTotal;
                    totalCasinoExchange += s.casinoExchangeTotal;

                    totalAccumulatePoints += s.accumulatePoints;
                    totalAccumulateManagerAddCash += s.accumulateManagerAddCash;
                    totalAccumulateManagerAddPoint += s.accumulateManagerAddPoint;
                    totalAccumulateFirstRechargeBonusPoint += s.accumulateFirstRechargePoint;
                    totalAccumulateDayRechargeBonusPoint += s.accumulateDayRechargePoint;
                    totalAccumulateBetFailPoint += s.accumulateBetFailPoint;
                    totalAccumulateBetFail2RefPoint += s.accumulateBetFail2RefPoint;

                    totalUser += s.siteMemberCount;
                    totalBetCount += (s.sportsBetCount);
                    totalInplayBetCount += (s.inplayBetCount);
                    totalInplayBetAmount += (s.inplayBetCashTotal);
                    totalBetAmount += (s.sportsBetCashTotal);
                    totalWinAmount += (s.sportsBetWinCashTotal);
                    totalBetCancelAmount += (s.sportsBetCancelCashTotal);
                    totalBetSpecialAmount += (s.sportsBetSpecialCashTotal);
                    totalInplayWinAmount += (s.inplayBetWinCashTotal);
                    totalInplayBetCancelAmount += (s.inplayBetCancelCashTotal);

                    totalLBetCount += s.leisureBetCount;
                    totalLBetAmount += s.leisureBetCashTotal;
                    totalLWinAmount += s.leisureBetWinCashTotal;
                    totalLCancelAmount += s.leisureBetCancelCashTotal;
                    totalLSpecialAmount += s.leisureBetSpecialCashTotal;

                    totalPatnerStAmount += s.partnerStatisticAmount;
                    totalWinPatnerStAmount += s.partnerWinStatisticAmount;
                    totalPartnerBetStatisticAmount += s.partnerBetStatisticAmount;
                    totalPartnerBetWinStatisticAmount += s.partnerBetWinStatisticAmount;

                    totalPartnerLBetStatisticAmount += s.partnerLBetStatisticAmount;
                    totalPartnerLBetWinStatisticAmount += s.partnerLBetWinStatisticAmount;

                    totalLoginUserCount += s.loginUsercount;

                    casinoBetCountTotal += s.casinoBetCountTotal;
                    casinoBetCashTotal += s.casinoBetCashTotal;
                    casinowinCashTotal += s.casinowinCashTotal;
                    casinocancelCashTotal += s.casinocancelCashTotal;
                    slotBetCountTotal += s.slotBetCountTotal;
                    slotBetCashTotal += s.slotBetCashTotal;
                    slotwinCashTotal += s.slotwinCashTotal;
                    slotcancelCashTotal += s.slotcancelCashTotal;
                    fishRechargCashTotal += s.fishRechargCashTotal;
                    fishExchangeCashTotal += s.fishExchangeCashTotal;


                    reachCasinoBetCountTotal += s.reachCasinoBetCountTotal;
                    reachCasinoBetCashTotal += s.reachCasinoBetCashTotal;
                    reachCasinowinCashTotal += s.reachCasinoWinCashTotal;
                    reachSlotBetCountTotal += s.reachSlotBetCountTotal;
                    reachSlotBetCashTotal += s.reachSlotBetCashTotal;
                    reachSlotwinCashTotal += s.reachSlotWinCashTotal;

                    honorCasinoBetCountTotal += s.honorCasinoBetCountTotal;
                    honorCasinoBetCashTotal += s.honorCasinoBetCashTotal;
                    honorCasinowinCashTotal += s.honorCasinoWinCashTotal;
                    honorSlotBetCountTotal += s.honorSlotBetCountTotal;
                    honorSlotBetCashTotal += s.honorSlotBetCashTotal;
                    honorSlotwinCashTotal += s.honorSlotWinCashTotal;

                })
                this.totalUser = totalUser;
                this.totalRecharge = totalRecharge;
                this.totalExchange = totalExchange;
                this.totalExchangePartner = totalExchangePartner;
                this.totalCasinoRecharge = totalCasinoRecharge;
                this.totalCasinoExchange = totalCasinoExchange;
                this.totalAccumulatePoints = totalAccumulatePoints;
                this.totalBetCount = totalBetCount;
                this.totalBetCancelAmount = totalBetCancelAmount;
                this.totalBetSpecialAmount = totalBetSpecialAmount;
                this.totalBetAmount = totalBetAmount;
                this.totalWinAmount = totalWinAmount;
                this.totalInplayWinAmount = totalInplayWinAmount;
                this.totalInplayBetCancelAmount = totalInplayBetCancelAmount;
                this.totalInplayBetAmount = totalInplayBetAmount;
                this.totalLBetCount = totalLBetCount;
                this.totalInplayBetCount = totalInplayBetCount;
                this.totalLBetAmount = totalLBetAmount;
                this.totalLWinAmount = totalLWinAmount;
                this.totalREProfit = this.totalRecharge - this.totalExchange - this.totalExchangePartner;
                this.totalCasinoREProfit = this.totalCasinoRecharge - this.totalCasinoExchange;
                this.totalBetProfit = this.totalBetAmount - this.totalWinAmount;
                this.totalPatnerStAmount = totalPatnerStAmount;
                this.totalWinPatnerStAmount = totalWinPatnerStAmount;
                this.totalPartnerBetStatisticAmount = totalPartnerBetStatisticAmount;
                this.totalPartnerBetWinStatisticAmount = totalPartnerBetWinStatisticAmount;
                this.totalPartnerLBetStatisticAmount = totalPartnerLBetStatisticAmount;
                this.totalPartnerLBetWinStatisticAmount = totalPartnerLBetWinStatisticAmount;
                this.totalLoginUserCount = totalLoginUserCount;
                this.totalAccumulateManagerAddCash = totalAccumulateManagerAddCash;
                this.totalAccumulateManagerAddPoint = totalAccumulateManagerAddPoint;
                this.totalAccumulateFirstRechargeBonusPoint = totalAccumulateFirstRechargeBonusPoint;
                this.totalAccumulateDayRechargeBonusPoint = totalAccumulateDayRechargeBonusPoint;
                this.totalAccumulateBetFailPoint = totalAccumulateBetFailPoint;
                this.totalAccumulateBetFail2RefPoint = totalAccumulateBetFail2RefPoint;
                this.totalLCancelAmount = totalLCancelAmount;
                this.totalLSpecialAmount = totalLSpecialAmount;

                this.casinoBetCountTotal = casinoBetCountTotal;
                this.casinoBetCashTotal = casinoBetCashTotal;
                this.casinowinCashTotal = casinowinCashTotal;
                this.casinocancelCashTotal = casinocancelCashTotal;
                this.slotBetCountTotal = slotBetCountTotal;
                this.slotBetCashTotal = slotBetCashTotal;
                this.slotwinCashTotal = slotwinCashTotal;
                this.slotcancelCashTotal = slotcancelCashTotal;
                this.fishRechargCashTotal = fishRechargCashTotal;
                this.fishExchangeCashTotal = fishExchangeCashTotal;

                this.reachCasinoBetCountTotal = reachCasinoBetCountTotal;
                this.reachCasinoBetCashTotal = reachCasinoBetCashTotal;
                this.reachCasinowinCashTotal = reachCasinowinCashTotal;
                this.reachSlotBetCountTotal = reachSlotBetCountTotal;
                this.reachSlotBetCashTotal = reachSlotBetCashTotal;
                this.reachSlotwinCashTotal = reachSlotwinCashTotal;

                this.honorCasinoBetCountTotal = honorCasinoBetCountTotal;
                this.honorCasinoBetCashTotal = honorCasinoBetCashTotal;
                this.honorCasinoWinCashTotal = honorCasinowinCashTotal;
                this.honorSlotBetCountTotal = honorSlotBetCountTotal;
                this.honorSlotBetCashTotal = honorSlotBetCashTotal;
                this.honorSlotWinCashTotal = honorSlotwinCashTotal;
            },

        },
        created() {
            this.getMainStatistic();
            document.body.scrollIntoView()
        },
        mounted() {
            document.body.scrollIntoView()
        }
    }
</script>

<style scoped>
    .table50 {
        border: 2px solid #0c0e0e;
        margin-top: 5px;
        font-weight: 600;
    }


    .totalText {
        font-size: 12px;
        font-weight: bold;
        font-style: italic;
        color: #3a8ee6;
    }

</style>